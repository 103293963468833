.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
}

.main {
  max-width: 1300px;
  margin: 2vw;
}

.container {
  max-width: 1300px;
  margin: 40px 0;
  padding: 20px;
  padding-bottom: 8px;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  background-color: white;
  font-family: "Poppins";
}

.child_left {
  flex: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: clamp(10px, 2vw, 40px);
  background: linear-gradient(to bottom, #166bdf, #166bdf, #3aacee);
  color: white;
  border-radius: 12px;
  max-width: 600px;
  box-sizing: border-box;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.child_left:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  transform: translateY(-4px);
}

.child_right {
  flex: 6;
  padding: clamp(10px, 2vw, 40px);
  padding-bottom: 0;
  max-width: 900px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.sp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.gif {
  margin-top: 10px;
  width: clamp(200px, 21vw, 100%);
}

.main_hed_p {
  display: grid;
}

.para {
  font-size: clamp(10px, 4vw, 22px);
  line-height: 27px;
  font-weight: 200;
}

.box_bigHed {
  font-size: 28px;
  display: flex;
  font-weight: 700;
}

.main_hed {
  color: white !important;
  font-size: clamp(16px, 6vw, 42px);
  font-weight: 600;
  margin: 0;
}

.space {
  height: clamp(0px, 6vw, 50px);
}

.row {
  display: flex;
  justify-content: space-evenly;
}

.box_hed {
  text-align: left;
  font-size: clamp(14px, 1.5vw, 26px);
  line-height: 1;
  margin-bottom: 8px;
  font-weight: 500;
}

.ic {
  aspect-ratio: 5/4 !important;
  height: 16px;
}

.flx_col {
  display: flex;
  flex-direction: column;
}

.flx_row {
  display: flex;
  gap: 10px;
  align-items: center;
}
.flx_row > span {
  font-size:  clamp(12px, 1.5vw, 24px);
  font-weight: 300;
}


.flx_hlf {
  /* min-width: 10px; */
  flex: 1;
  width: 25%;
  min-width: 130px;
  overflow-x: hidden;
}

.err {
  color: red;
}

.flx_btm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
  margin-bottom: 30px;
}

.field {
  width: 100%;
  margin-bottom: 16px;
}

.btn {
  padding: 12px 48px;
  font-size: 16px;
  align-self: flex-start;
  border: 1px solid white;
  color: white;
  background-color: #3a95ca;
  border-radius: 9px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
.btn:hover {
  color: #1569df;
  background-color: white;
  border: 2px solid blue;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
}

.flx {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

.flx2 {
  display: flex;
  flex-direction: column;
  gap:  clamp(10px, 6vw, 40px);
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}
.flx2 > div {
  flex: 1;
  min-width: 230px;
}

.bottom_image {
  align-self: center;
  width: clamp(200px, 18vw, 100%);
}

.bottom_line_input {
  border: none;
  border-bottom: 2px solid #000;
  padding: 5px;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.add {
  position: relative;
  bottom: 0;
}

.more_info {
  align-self: center;
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  margin-top: 100px;
  margin-bottom: 160px;
}

.card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 20px;
  width: 25vw;
  min-width: 300px;
  max-width: 30%;
  background-color: white;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  background-color: #abdefb;
  border: 4px solid #3a95ca;
  box-shadow: 0 12px 8px rgba(0, 0, 0, 0.4);
  transform: translateY(-4px);
}

.card_hed {
  font-size: 18px;
  font-weight: 700;
}

.card_bd {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 18px;
}

.card_img {
  height: 180px;
}

.bannerCointainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: clamp(50px, 20vw, 360px);
  background-image: url("/public/contactus_files/contactus_banner.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bannerImg {
  width: 100%;
  height: auto;
}

.overText {
  text-align: center;
  font-size: clamp(24px, 5vw, 60px);
  font-weight: bold;
  color: #2d80a5;
}

.textStyle {
  font-size: clamp(36px, 7vw, 90px);
  color: white;
  text-shadow: -1px -1px 0 #2d80a5, 1px -1px 0 #2d80a5, -1px 1px 0 #2d80a5,
    1px 1px 0 #2d80a5;
}
