.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
}

.main {
  margin: 0 10px;
  padding: 0 10px;
  max-width: 1600px;
  background-color: white;
}

.body{
  padding: 0 6%;
  display: flex;
  flex-direction: column;
}

.container {
  margin: 40px 0;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  align-self: center;
  font-family: "Poppins";
}

.qes{
  margin-top: 60px;
  font-size: 1.8em;
  font-weight: 600;
}

.qes_li > li{
  margin-top: 6px;
  font-weight: 500;
}

.hed1{
  margin: 20px 0;
  padding: 10px 0;
  font-size: 2em;
  font-weight: 500;
  border-radius: 12px;
  align-items: center;
  background-image: url('https://64.media.tumblr.com/9b21c9ffae9b31777331159753fe2f23/tumblr_ontrlf7Uem1uzwgsuo1_400.gif');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.yt_vd{
  margin: 30px 0;
  align-self: center;
}