.main_Con{
  background-image: url('../../../public/login_files/getin_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
}

.card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 8px 20px rgba(0, 0, 0, 0.5), -2px 0 12px rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 400px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05); /* Slightly increase the size of the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase the shadow for a lifting effect */
}

.card h2 {
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
}

.card p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
}

.btn {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}


.loginBtn {
  background-color: #0056b3;
}

.loginBtn a{
 color:white;
 text-decoration: none;
}


.signupBtn {
  background-color: #218838;
}

.signupBtn a {
  color:white;
  text-decoration: none;
}
