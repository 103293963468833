.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  margin-top:clamp(50px, 10vw, 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes big_small {
  0% {
    transform: scal(1.3); /* Start position */
  }
  50% {
    transform: translateY(-20px); /* Move up */
  }
  100% {
    transform: translateY(0), scale(1); /* End position */
  }
}

.m0{
  margin: 0 !important;
}

section {
  margin-bottom: 60px;
  width: 80vw;
  max-width: 1300px;
}

.cardTn1 {
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}
.cardTn1:hover {
  box-shadow: 4px 8px 14px #91c2f9, -4px -6px 14px #7ab0ef;
  transform: scale(1.05);
}

.cardTn2 {
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}
.cardTn2:hover {
  box-shadow: 4px 8px 14px #f2d5dd, -4px -6px 14px #f2d5dd;
  transform: scale(1.04);
}

.des {
  font-size: clamp(10px, 5vw, 26px) !important;
  margin: 0;
  align-self: flex-start;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.hero > .heroleft {
  flex: 1;
  min-width: min-content;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  align-items: start;
  gap: 20px;
  width: 45%;
}

.h1 {
  font-size: clamp(26px, 8vw, 42px);
  font-weight: 700;
  text-align: center;
}
.h1 > span {
  color: #3a95ca;
  font-size: clamp(30px, 10vw, 42px);
  font-weight: 700;
  margin: 0;
}
.h2 {
  font-size: clamp(21px, 4vw, 42px);
  font-weight: 700;
  text-align: center;
}
.h3 {
  font-size: clamp(15px, 4vw, 42px);
  font-weight: 700;
}
.h4 {
  font-size: clamp(21px, 4vw, 42px);
  font-weight: 700;
}
.h5 {
  font-size: clamp(26px, 8vw, 42px);
  font-weight: 700;
}
.h5 > span {
  color: #3a95ca;
  font-size: clamp(30px, 10vw, 42px);
  font-weight: 700;
  margin: 0;
}

.hero .heroleft h6 {
  font-size: clamp(20px, 1rem, 26px);
  font-weight: 500;
}
.hero .heroleft p {
  font-size: clamp(20px, 31rem, 26px);
  font-weight: 500;
  letter-spacing: 1px;
}
.hero .heroright {
  width: clamp(260px, 20vw, 80%);
  display: flex;
  justify-content: center;
}
.hero .heroright img {
  width: 84%;
  height: 84%;
}
.openbtn img {
  width: 30px;
  text-align: center;
}
.openbtn {
  width: 100%;
  max-width: 400px;
  font-size: clamp(10px, 4vw, 26px);
  background: #3a95ca;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  animation: big_small 2s ease-in-out infinite;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.arrdown {
  margin: 0 0 30px 0;
}

.rocket {
  transform: rotate(52deg);
  width: 20%;
}

.qtext {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.qtext > p {
  margin: 0 46px;
}

.qtext1 {
  align-self: flex-start;
}
.qtext2 {
  align-self: flex-end;
}

.rocdiv {
  margin-top: 50px;
}
.rocdiv > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap:  clamp(0px, 1vw, 26px);
  padding: 30px;
  border-radius: 12px;
  background-color: #ffe3bb;
  margin-bottom: 160px;
}


.rocdiv div p {
   font-size: clamp(14px, 1vw, 26px);
  font-weight: 600;
  text-align: center;
}
.rocdiv > div2 {
  max-width: 1200px;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  margin-top: -180px;
  margin-bottom: 60px;
  z-index: -1;
}


.features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.features h3 {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.features h3 span {
  color: #0875bd;
}
.features h4 {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.featureslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 20px;
}

.featureimg {
  width: 22%;
}

.feature {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  min-width: 300px;
  width: 30%;
  background-color: #d1e3f8;
  padding: 20px 10px;
  border-radius: 12px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
.feature:nth-child(even) {
  background-color: #e2f4ff;
}

.feature > div:nth-child(1) {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.feature > div:nth-child(3) {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.featuredetails ul {
  padding: 20px;
  text-align: start;
}
.featuredetails ul li {
  font-size: clamp(14px, 1vw, 26px);
  padding-bottom: 10px;
}

.feature > div > p {
  text-align: end;
  color: #0875bd;
  font-weight: 700;
  text-decoration: none;
}

.feature > div > p > a {
  text-decoration: none;
}

.feature div a {
  font-weight: 700;
}

.arr {
  width: 30px;
}

.btn2 {
  width: 100%;
  font-size: 26px;
  background: #3a95ca;
  color: #fff;
  padding: 4px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.wedo {
  display: flex;
  flex-direction: column;
}
.wedo h3 {
  font-size: clamp(30px, 3rem, 40px);
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
}
.wedolist {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  row-gap: 80px;
}
.wedoitem {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: clamp(0px, 1.2vw, 40px);
  border-radius: 12px;
  box-sizing: border-box;
  background: linear-gradient(90deg, #fffcfd, #fbdadc, #889ef4);
}
.wedoitem:nth-child(even) {
  background: linear-gradient(-90deg, #fffcfd, #fbdadc, #889ef4);
}
.wedoitem img {
  max-height: 310px;
  max-width: 100%;
}
.wedoitemtxt {
  padding: 20px 30px;
  text-align: start;
  flex: 1;
}
.wedoitemtxt h4 {
  font-size: 3rem;
  padding-bottom: 10px;
  font-weight: 700;
}
.wedoitemtxt p {
  font-size: 1.2rem;
}

.about {
  background: url(/public/home/wave.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: clamp(0px, 10vw, 300px);
}
.aboutus {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
}
.aboutus div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 20px;
  text-align: start;
  flex: 6;
}
.aboutus div h3 {
  text-align: start;
  font-size: clamp(30px, 3rem, 50px);
  font-family: "karla";
}
.aboutus div p {
  font-size: 1.1rem;
  text-align: justify;
  text-indent: 60px;
}
.aboutus img {
  flex: 4;
  max-width: 100%;
}

.education {
  display: flex;
  gap: clamp(0px, 1.2vw, 40px);
  border-radius: 12px;
  padding: 14px 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: linear-gradient(to left, #bbd0ff, #9fbdff, #cfdeff, #f0f5ff);
}
.education > .edu_left {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.education > .edu_left > h4 {
  font-size: clamp(30px, 4.1vw, 60px);
  font-weight: bold;
  line-height: 1;
  font-family: "Inter";
  margin-bottom: 14px;
}
.education > .edu_left > span {
  font-size: clamp(14px, 1.5vw, 28px);
  font-family: "Inter";
  font-weight: 400;
  line-height: 1;
  margin-bottom: 22px;
}
.education > .edu_left > p {
  font-size: clamp(14px, 1.5vw, 28px);
  font-family: "Inter";
  font-weight: 500;
  line-height: 1;
  color: #737171;
  margin-bottom: 24px;
}
.education > .edu_left > a {
  color: white;
  font-size: clamp(14px, 1.5vw, 26px);
  background-color: #4ba7fd;
  padding: 12px 44px;
  border-radius: 12px;
  max-width: max-content;
  font-family: "Inknut Antiqua";
  text-decoration: none;
}
.education > img {
  width: clamp(260px, 20vw, 80%);
  max-width: 100%;
}

.portfolio{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 10px 20px;
  background: #def3ff;
}
.portfolio .portxt {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  text-align: start;
}
.portxt h4 {
  font-size: 200%;
}
.portxt > p {
  font-weight: 500;
  font-size: 120%;
  line-height: 1;
  color: #696969;
}
.portfolio img {
  width: clamp(260px, 20vw, 80%);
  display: flex;
  justify-content: center;
}

.testim > h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:  clamp(20px, 5vw, 42px);
  font-weight: 700;
  color: #2400c5;
  text-align: center;
}
.testim h3 img:first-child {
  transform: scaleX(-1);
}
.testimlist {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  gap: clamp(30px, 5vw, 80px);
  justify-content: center;
  margin: 100px 20px 20px;
}
.testimitem {
  min-width: 30%;
  width: 300px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: clamp(1px, 1vw, 10px);
  background: url(/public/home/test.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: transform 0.5s ease-in-out;
}
.testimitem img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.testimitem h4 {
  font-size: clamp(18px, 1.6vw, 42px);
}
.testimitem h6 {
  font-size: 1.2rem;
}
.testimitem p {
  font-size: clamp(12px, 1vw, 1.2rem);
  max-width: 60%;
  text-align: center;
  overflow-wrap: break-word;
  padding-right: 10px;
  line-height: 1.5;
}
.dotsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.dot {
  height: 16px;
  width: 16px;
  margin: 0 5px;
  background-color: #cff4ff;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.activeDot {
  height: 22px;
  width: 22px;
  background-color: #368bbd;
}
.active {
  transform: translateX(0);
}
.profileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
