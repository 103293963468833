.page {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  text-align: left;
  padding: 10px 10vw;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .page {
    padding: 10px 4vw;
  }
}

.logo {
  height: 120px;
  margin-bottom: 40px;
  align-self: center;
}

.el_card {
  flex: 1;
  display: flex;
  min-width: 300px;
  max-width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(231, 210, 248);
  box-shadow: 0 4px 8px rgba(170, 18, 204, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 16px;
  margin: 20px 5px;
  font-size: clamp(12px, 2vw, 16px);
  box-sizing: border-box;
}

.el_card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(127, 2, 156, 0.5);
}

.un {
  margin-top: 0;
  text-decoration: underline;
}

.el_card p {
  color: #555;
}

.main_hed1 {
  margin-top: 30px;
  line-height: 1;
  font-size: clamp(20px, 4vw, 50px);
  font-weight: 700;
  color: rgb(27, 25, 27);
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}
.main_hed2 {
  margin-bottom: 30px;
  font-size: 50px;
  font-weight: 700;
  color: #5a2989;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}
.main_subhed {
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 2px;
  color: rgb(71, 71, 71);
}

.hl {
  margin: 20px 0 70px 0;
  align-self: center;
  transform: scale(1.2);
}

.h1 {
  display: inline;
  font-size: clamp(20px, 3vw, 36px);
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  margin: 0;
  align-self: flex-start;
}

.h2 {
  width: 300px;
  height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: white;
  background-image: url("/public/upstox_files/ribben.png");
  background-size: cover;
  background-position: center;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  align-self: center;
}

.h3 {
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
  background-color: rgb(234, 209, 248);
  border-radius: 16px;
  padding: 0 16px;
  align-self: self-start;
}
.h5 {
  margin-top: 20px;
  font-weight: 700;
}

.h6 {
  margin-top: 20px;
  font-weight: 800;
  font-size: large;
}

.highlight_sm {
  font-size: 1.4em;
  font-weight: bold;
  color: white;
  border: 2px solid #73a824;
  padding: 10px;
  background: rgb(172, 87, 241);
  text-align: center;
  border-radius: 5px;
  text-shadow: 2px 2px 4px rgba(206, 153, 231, 0.6);
  display: inline-block;
}

.highlight {
  font-size: 2em;
  font-weight: bold;
  color: white;
  border: 6px solid #73a824;
  padding: 10px;
  background: linear-gradient(45deg, #c781f0, #c561ff, #8510f3);
  align-self: center;
  border-radius: 5px; /* Rounded corners for the border */
  text-shadow: 2px 2px 4px rgba(206, 153, 231, 0.6);
  display: inline-block; /* Ensure border wraps the text */
}

.note {
  padding: 11px;
  width: 340px;
  height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: white;
  background-image: url("/public/upstox_files/ribben2.png");
  background-size: cover;
  background-position: center;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  align-self: center;
}

.icon {
  width: 40px;
  vertical-align: bottom;
}

.hor {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
}

.mt_l {
  margin-top: 50px;
}
.mr {
  margin-top: 20px;
}

.docList {
  list-style-type: none; /* Remove default bullet points */
  padding-left: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  max-width: 100%;
}

.docList > li {
  font-size: clamp(14px, 2vw, 20px);
  position: relative; /* For absolute positioning of nested items */
  padding: 10px; /* Space around the list items */
  border: 2px solid #ccc; /* Light grey border */
  margin-bottom: 5px; /* Space between items */
  background-color: #e6f7ff; /* Light blue background */
  border-radius: 4px; /* Rounded corners */
}

/* Highlighting the second level */
.docList ul > li {
  list-style-type: bullet;
  font-size: clamp(14px, 2vw, 20px);
  background-color: #d0f0c0; /* Light green background */
  border: 2px solid #a9d9a8; /* Green border */
  margin-left: 20px; /* Indent second-level items */
}

/* Highlighting the third level */
.docList ul ul > li {
  list-style-type: disc;
  border: 0px solid #f1b0b7; /* Light pink border */
  margin-left: 40px; /* Indent third-level items */
  overflow-wrap: anywhere;
}

/* Adding a hover effect */
.docList li:hover {
  background-color: #e5dbff; /* Light gray background on hover */
  border-color: #f9eeff; /* Darker border color on hover */
}

/* Adding a focus effect for accessibility */
.docList li:focus-within {
  outline: 3px solid #007bff; /* Blue outline for focused elements */
}

.tbl {
  width: 100%;
  border-collapse: separate;
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

.tbl thead tr th {
  color: #e6e6fa;
  background-color: #7a0fc6;
  font-size: clamp(10px, 2vw, 28px);
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #dcdcdc;
}

.tbl tbody tr td {
  padding: clamp(0px, 1.5vw, 16px);
  font-size: clamp(10px, 1.5vw, 16px);
  border: 2px solid rgb(133, 153, 245);
  border-bottom: 1px solid red;
}

.tbl tbody tr:hover {
  color: #f9f9f9;
  background-color: #ae53ef;
}

@keyframes moveRocket {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right top;
  }
}
.bg_rocket {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url("/public/upstox_files/rocket.png");
  background-repeat: no-repeat;
  background-position: center bottom; /* Initial position */
  animation: moveRocket 50s linear infinite;
  /* background-size: cover; */
  /* align-content: center; */
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0); /* Start position */
  }
  50% {
    transform: translateY(-20px); /* Move up */
  }
  100% {
    transform: translateY(0); /* End position */
  }
}
.up_down {
  max-height: 350px;

  animation: moveUpDown 2s ease-in-out infinite;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  40% {
    opacity: 1;
    transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    transform: translateY(80px);
  }
  80% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(0);
  }
}

.bounce_In {
  animation: bounceIn 2s ease-out forwards;
  animation-timeline: view();
  animation-range: entry 0 cover 40%;
}

@keyframes big_small {
  0% {
    transform: scal(1.3); /* Start position */
  }
  50% {
    transform: translateY(-20px); /* Move up */
  }
  100% {
    transform: translateY(0), scale(1); /* End position */
  }
}
.trn {
  animation: big_small 2s ease-in-out infinite;
  transform: scale(1.1);
  transition: transform 0.3s ease;
}
.trn:hover {
  transform: scale(1.2);
}

.cardContainer {
  position: relative;
  margin: 40px 20px;
  padding: 36px 20px 20px 20px;
  max-width: 480px;
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  color: #f8f8f8;
  background-color: #b05bff;
  border-radius: 15px;
  box-shadow: 6px 10px 12px rgba(170, 18, 204, 0.3),
    -2px -4px 14px rgba(170, 18, 204, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease,
    background-color 0.3s ease;
}
.cardContainer:hover {
  background-color: #9422ff;
  box-shadow: 8px 12px 16px rgba(170, 18, 204, 0.8),
    -4px -6px 18px rgba(170, 18, 204, 0.8); /* Adjusted shadow on hover */
  transform: scale(1.05); /* Slightly scales up the element on hover */
}

.avatar {
  position: absolute;
  top: -50px;
  left: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid rgb(239, 186, 255);
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 10px rgba(170, 18, 204, 0.8);
}

/* Basic styling for the animated line */
.animatedLine {
  position: relative;
  width: 80%;
  height: 4px; /* Line thickness */
  background-color: #8510f3; /* Line color */
  margin: 20px auto; /* Center the line horizontally */
  border-radius: 2px; /* Slightly rounded edges */
  animation: lineExpandShrink 3s ease-in-out infinite;
}

/* Flames at both ends */
.animatedLine::before,
.animatedLine::after {
  content: "";
  position: absolute;
  width: 30px; /* Width of flames */
  height: 30px; /* Height of flames */
  background-color: #c9630a; /* Flame color */
  border-radius: 50%;
  animation: flameBurn 1.5s infinite ease-in-out;
}

/* Position flames at the left and right ends */
.animatedLine::before {
  top: -15px; /* Adjust vertical positioning */
  left: -15px; /* Adjust horizontal positioning */
}

.animatedLine::after {
  top: -15px; /* Adjust vertical positioning */
  right: -15px; /* Adjust horizontal positioning */
}

/* Keyframes for the flame effect */
@keyframes flameBurn {
  0%,
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
}

/* Keyframes for the line width animation */
@keyframes lineExpandShrink {
  0%,
  100% {
    width: 80%;
  }
  50% {
    width: 100%;
  }
}

.attention {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 14px;
  border: 3px dashed rgb(163, 17, 163);
  padding: 12px 20px;
  font-size: clamp(18px, 2vw, 34px);
  color: rgb(153, 0, 255);
  align-self: center;
  font-weight: 800;
  margin-bottom: 40px;
}

.attention > div > img{
  width: clamp(40px, 2vw, 100px);
  height: clamp(40px, 2vw, 100px);
}
