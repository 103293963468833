    .notFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: #343a40;
  }
  
  .notFoundContainer h1 {
    font-size: 6rem;
    margin: 0;
  }
  
  .notFoundContainer h2 {
    font-size: 2rem;
    margin: 0.5rem 0;
  }
  
  .notFoundContainer p {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
  
  .notFoundContainer button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .notFoundContainer button:hover {
    background-color: #0056b3;
  }
  