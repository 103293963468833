.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
}

.main {
  margin: 0 10px;
  padding: 0 10px;
  width: 80%;
  min-height: 90vh;
  max-width: 1600px;
  background-color: white;
}

.sec1 {
  height: 300px;
  margin: 20px 0;
  padding: 10px 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-image: url("https://blog.khanacademy.org/wp-content/uploads/2021/08/blog_bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.sec1 > h1 {
  color: white;
}

.sec1 > p {
  font-size: 1.3em;
  font-weight: 600;
}

.searchBox {
  width: 50%;
  padding: 6px;
  border: 1px solid black;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background-color: #ffffff;
}

.searchInput {
  flex: 1;
  outline: none;
  border: none;
}

.body {
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blogs {
  width: 100%;
  list-style: none;
}

.blogs > li {
  padding-top: 14px;
  border-bottom: 1px solid grey;
  cursor: pointer;
  padding-bottom: 1rem;
}

.summary {
  margin-bottom: 1rem;
  color: grey;
  font-size: 0.9em;
}

.rdM{
  font-weight: 500;
  color: rgb(16, 148, 220);
  background-color: transparent;
}