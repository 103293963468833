.page_container {
  margin-top: 56px;
}

.container {
  height: calc(100vh - 56px);
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #5865F2;
  background-image: url("../../../public/signup_files/signup_bg.jpg");
}

.formContainer {
  margin: auto;
  height: 600px;
  display: flex;
  border: 3px solid rgb(63, 235, 43);
  align-items: center;
  background: #fff;
  padding: 14px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.left {
  flex: 1;
  margin-right: 20px;
  width: 400px;
  /* height: 500px; */
}

.img{
  width: 400px;
}

.right {
  flex: 1;
  width: 400px;
  height: 760px;
  padding: 20px 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: 4px solid rgb(39, 171, 223);
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.3);
}

.h1 {
  font-size: 24px;
  font-weight: 600;
  font-size: 1.8em;
}

.des {
  font-weight: 100;
  color: gray;
  font-size: 1.4em;
}

.label {
  font-weight: 500;
  color: rgb(90, 90, 90);
  margin-bottom: 4px;
}

.input {
  border-radius: 6px;
  padding: 12px 12px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 1.2em;
  border: 2px solid rgb(98, 194, 250);
  outline: none;
}
.input:focus {
  border: 3px solid rgb(0, 162, 255);
  outline: none;
}

.flx {
  display: flex;
  flex-direction: column;
}

.submit {
  width: 100%;
  margin: 26px 0;
  padding: 12px;
  font-size: 1.2em;
  border-radius: 8px;
  background-color: #138338;
}

.link a {
  margin-left: 6px;
  color: blue;
  text-decoration: none;
  font-size: 1.2em;
}

.last {
  text-align: center;
}

.formGroup input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.errorInput {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 3px solid red;
  border-radius: 3px;
}

.errorMessage {
  color: red;
  font-size: 0.875em;
}

button {
  padding: 10px 15px;
  border: none;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}
